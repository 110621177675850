import React from 'react';
import PropTypes from 'prop-types';
import pkg from '../../package.json';
import './Version.scss';

function Version(props:any) {
  console.log('Version',pkg.version);

  return (
    <div className="version">
      { process.env.REACT_APP_ENV === 'development' ? 'dev' : ''}
      <br/>{pkg.version}<br/>{pkg.lastUpdate}
    </div>
  );
}

Version.propTypes = {
  
};

export default Version;
