import React from 'react';
import './App.scss';
// import { makeStyles } from '@material-ui/core/styles';

import Container  from '@material-ui/core/Container';
import Grid       from '@material-ui/core/Grid';
import Paper      from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import Button     from '@material-ui/core/Button';


import DialogTitle     from '@material-ui/core/DialogTitle';
import Dialog          from '@material-ui/core/Dialog';
import DialogActions   from '@material-ui/core/DialogActions';
import DialogContent   from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CircularProgress from '@material-ui/core/CircularProgress';

import ErrorIcon from '@material-ui/icons/Error';

import Maintenant      from './inc/Maintenant';
import Aujourdhui      from './inc/Aujourdhui';
import Soldes          from './inc/Soldes';
import Participations  from './inc/Participations';
import Commandes       from './inc/Commandes';
import Ardoise         from './inc/Ardoise';
import Contact         from './inc/Contact';
import Adresse         from './inc/Adresse';
import Horaires        from './inc/Horaires';
import Version         from './inc/Version';


// TODO a comprendre...
// const useStyles = makeStyles({
  
// });

class App extends React.Component<any,any> {

  static propTypes = {};

  constructor(props: any) {
    super(props);
    this.state = {

      // variables liees a l'app
      isCommandeDetailsOpened: false,
      commandeDetails: undefined,
      isFetching: false,
      isFetchErrorOpened: false,
      fetchError: undefined

    };
    this.fetchDatas = this.fetchDatas.bind(this);
    this.onOpenCommandeDetails = this.onOpenCommandeDetails.bind(this);
    this.handleCloseCommandeDetails = this.handleCloseCommandeDetails.bind(this);
    // this.openFetchError = this.openFetchError.bind(this);
    this.handleCloseFetchError = this.handleCloseFetchError.bind(this);
  }

  componentDidMount() {
    this.fetchDatas(
      (dataSuccess) => {
        this.setState((state) => {
          return ({
            isFetching: false,

            maintenant : dataSuccess.maintenant,
            commandesEnAttente: dataSuccess.commandes!==undefined ? dataSuccess.commandes.length : 0,
            commandesEnAttenteAll: dataSuccess.commandesEnAttenteAll,
            commandesEnAttenteAllAdherents: dataSuccess.commandesEnAttenteAllAdherents,
            aujourdhui: dataSuccess.aujourdhui,
            soldeEuros: dataSuccess.soldeEuros,
            soldeHeures: dataSuccess.soldeHeures,
            participations: dataSuccess.participations,
            commandes: dataSuccess.commandes,
            ardoise: dataSuccess.ardoise,
            ardoiseUpdate: dataSuccess.ardoiseUpdate,

            plateformeURL: dataSuccess.plateformeURL ? dataSuccess.plateformeURL.replace('%(login)','sandrine.prousteau@gmail.com') : undefined,
            name: dataSuccess.name,
            logoURL: dataSuccess.logoURL,
            email: dataSuccess.email,
            adresse: dataSuccess.adresse,
            gmapURL: dataSuccess.adresse ? 'https://www.google.com/maps/search/%(address)/?hl=fr-FR'.replace('%(address)',dataSuccess.adresse.replace('<br>',' ').replace('<br/>',' ')) : undefined,
            horaires: dataSuccess.horaires,
            texte3: dataSuccess.texte3,
            texte5: dataSuccess.texte5,
            texte7: dataSuccess.texte7,
            texte9: dataSuccess.texte9
          });
        });
      },
      (dataError) => {
        console.log(dataError);
        let fetchError = {
          code: dataError.errorCode!==undefined ? dataError.errorCode : undefined,
          message: dataError.errorMessage!==undefined ? dataError.errorMessage : 'Erreur lors de la récupération des données',
          text: dataError.errorText!==undefined ? dataError.errorText : dataError+''
        };

        this.setState((state) => {
          return ({
            isFetching: false,

            isFetchErrorOpened: true,
            fetchError: fetchError,

            maintenant : dataError.maintenant,
            commandesEnAttente: dataError.commandes!==undefined ? dataError.commandes.length : 0,
            commandesEnAttenteAll: dataError.commandesEnAttenteAll,
            commandesEnAttenteAllAdherents: dataError.commandesEnAttenteAllAdherents,
            aujourdhui: dataError.aujourdhui,
            soldeEuros: dataError.soldeEuros,
            soldeHeures: dataError.soldeHeures,
            participations: dataError.participations,
            commandes: dataError.commandes,
            ardoise: dataError.ardoise,
            ardoiseUpdate: dataError.ardoiseUpdate,

            plateformeURL: dataError.plateformeURL ? dataError.plateformeURL.replace('%(login)','sandrine.prousteau@gmail.com') : undefined,
            name: dataError.name,
            logoURL: dataError.logoURL,
            email: dataError.email,
            adresse: dataError.adresse,
            gmapURL: dataError.adresse ? 'https://www.google.com/maps/search/%(address)/?hl=fr-FR'.replace('%(address)',dataError.adresse.replace('<br>',' ').replace('<br/>',' ')) : undefined,
            horaires: dataError.horaires,
            texte3: dataError.texte3,
            texte5: dataError.texte5,
            texte7: dataError.texte7,
            texte9: dataError.texte9
          });
        });
      }
    );
  }
  componentWillUnmount() {
    
  }

  fetchDatas(cbSuccess,cbError){
    let self = this;

    // './mocks/mock1.json' : valeurs ok
    // './mocks/mock2.json' : valeurs ok mais erreur ou alerte a reporter
    // './mocks/mock3.json' : format json pete
    // 'http://localhost:8001/json-services/test.php?idsite=1&idadherent=1' : docker abricot

    let MOCK = '/mocks/mock1.json';
    let LIVE = 'http://localhost:8001/json-services/test.php';

    let URL = process.env.REACT_APP_URL;
    if(URL===undefined || URL==='')
      URL = MOCK;

    this.setState({
      isFetching: true
    }, () => {
      const requestOptions = { 
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      };
      fetch(URL+'?idsite=1&idadherent=1', requestOptions)  // TODO url avec un timestamp
        //.then(response => response.json())
        .then((response) => {
          console.log('Fetch response',response);
          if(response.ok) {
            // on verifie que c'est du json
            var contentType = response.headers.get("content-type");
            if(contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then(function(data) {
                
                console.log('Fetch datas',data);
                if(data && data.ok){
                  console.log('L\'opération fetch a renvoyé des donnees valides: ',data);
                  if(data.token)
                    localStorage.setItem('monepi_token', data.token);
                  if(cbSuccess) cbSuccess(data);
                }else{
                  console.warn('L\'opération fetch a renvoyé des donnees invalides ou contenant des alertes d\'erreur: ',data);
                  if(cbError) cbError(data);
                }

              });
            } else {
              console.log("Oops, nous n'avons pas du JSON!");
              if(cbError) cbError({errorCode:undefined,errorMessage:'Not JSON'});
            }
          } else {
            console.warn('L\'opération fetch a renvoyé des donnees invalides ou contenant des alertes d\'erreur: ',response);
            if(cbError) cbError(response);
          }          
        })
        .catch((error) => {
          console.error('Il y a eu un problème avec l\'opération fetch: ',error);
          if(cbError) cbError(error);
        });
    });
  }

  onCall(personne){
    if(!personne)
      return;
    if(!personne.contact)
      return;
    console.log('Phone',personne);
    if(personne && personne.contact!==undefined)
      window.open('tel:'+personne.contact);
  }

  onOpenCommandeDetails(commande) {
    this.setState({
      isCommandeDetailsOpened: true,
      commandeDetails: commande
    });
  }
  handleCloseCommandeDetails() {
    this.setState({
      isCommandeDetailsOpened: false,
      commandeDetails: undefined
    });
  }
  // openFetchError(fetchError) {
  //   this.setState({
  //     isFetchErrorOpened: true,
  //     fetchError: fetchError
  //   });
  // }
  handleCloseFetchError() {
    this.setState({
      isFetchErrorOpened: false,
      fetchError: undefined
    });
  }

  render (){
    console.log('Render',this.state);
    return (
      <div className="App">
        
        <Container>
          <Grid className="grid" container spacing={2} justify="center">
            <Grid item xs={12} md={6}>

              <Paper className={'live '} elevation={3}>

                <Typography variant="h5" color="textPrimary" align="center"><strong>Live</strong></Typography>

                <Maintenant
                  maintenant={this.state.maintenant}
                  commandesEnAttente={this.state.commandesEnAttente}
                  commandesEnAttenteAll={this.state.commandesEnAttenteAll}
                  commandesEnAttenteAllAdherents={this.state.commandesEnAttenteAllAdherents} />

                <Aujourdhui 
                  aujourdhui={this.state.aujourdhui}
                  onCall={this.onCall}/>

                TODO : visites annoncées

              </Paper>

            </Grid>
            <Grid item xs={12} md={6}>

              <Paper className={'moncompte '} elevation={1}>

                <Typography variant="h5" color="textPrimary" align="center"><strong>Mon compte</strong></Typography>

                <Soldes
                  soldeEuros={this.state.soldeEuros}
                  soldeHeures={this.state.soldeHeures}
                  plateformeURL={this.state.plateformeURL} />

                <Participations
                  participations={this.state.participations} />

                <Commandes
                  commandes={this.state.commandes}
                  onOpenCommandeDetails={this.onOpenCommandeDetails}
                  handleCloseCommandeDetails={this.handleCloseCommandeDetails}
                  isCommandeDetailsOpened={this.state.isCommandeDetailsOpened}
                  commandeDetails={this.state.commandeDetails} />

                

              </Paper>

            </Grid>
            <Grid item xs={12} md={6}>

              <Paper className={'news '} elevation={1}>

                <Typography variant="h5" color="textPrimary" align="center"><strong>News</strong></Typography>

                <Ardoise 
                  richText={this.state.ardoise}
                  updateText={this.state.ardoiseUpdate}/>

                TODO : pré-commandes en cours & commandes spéciales

              </Paper>

            </Grid>
            <Grid item xs={12} md={6}>

              <Paper className={'planning '} elevation={1}>

                <Typography variant="h5" color="textPrimary" align="center"><strong>Planning</strong></Typography>

                

                TODO : calendrier mois coulant + permanences prévues mois coulant + visites annoncées

              </Paper>

            </Grid>
            <Grid item xs={12} md={6}>

              <Paper className={'lepi '} variant="outlined" >

                <Typography variant="h5" color="textPrimary" align="center"><strong>{this.state.name}</strong></Typography>

                <img src={this.state.logoURL} alt={this.state.name} />

                <div className="separator"></div>

                <Contact 
                  email={this.state.email}/>

                <div className="separator"></div>

                <Adresse
                  adresseText={this.state.adresse}
                  gmapURL={this.state.gmapURL} />

                <div className="separator"></div>

                <Horaires
                  horaires={this.state.horaires} />

                {this.state.texte3 ? <div className="footer" dangerouslySetInnerHTML={{__html: this.state.texte3}}></div> : null}
                {this.state.texte5 ? <div className="footer" dangerouslySetInnerHTML={{__html: this.state.texte5}}></div> : null}
                {this.state.texte7 ? <div className="footer" dangerouslySetInnerHTML={{__html: this.state.texte7}}></div> : null}
                {this.state.texte9 ? <div className="footer" dangerouslySetInnerHTML={{__html: this.state.texte9}}></div> : null}

              </Paper>

            </Grid>
          </Grid>
          {this.state.isFetching ? (
          <div className="loading">
            <CircularProgress color="secondary" />
          </div>
          ) : null}
        </Container>

        <Dialog onClose={this.handleCloseFetchError} aria-labelledby="Erreur" fullScreen open={this.state.isFetchErrorOpened}>
          <DialogTitle id="commande-dialog">
            {this.state.fetchError ? (
              <>
              <ErrorIcon /> <span>{this.state.fetchError.message}</span>
            </>
            ) : null}
          </DialogTitle>
          <DialogContent className="dialog-wrapper">
            {this.state.fetchError ? (
            <>
              <div dangerouslySetInnerHTML={{__html: this.state.fetchError.text}}></div>
            </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" size="small" onClick={this.handleCloseFetchError}>Fermer</Button>
          </DialogActions>
        </Dialog>

        <Version />
      </div>
    );
  }
}

export default App;
