import React from 'react';
import PropTypes from 'prop-types';
import './Soldes.scss';

import Grid       from '@material-ui/core/Grid';

import Button       from '@material-ui/core/Button';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import KeyNumber     from '../inc/KeyNumber';

function Soldes(props:any) {

  let soldeEuros_status = '';
  if(props.soldeEuros<10){
    soldeEuros_status = 'danger';
  }else if(props.soldeEuros<50){
    soldeEuros_status = 'warn';
  }else{
    soldeEuros_status = 'success';
  }

  let soldeHeures_status = '';
  if(props.soldeHeures<1){
    soldeHeures_status = 'danger';
  }else if(props.soldeHeures<3){
    soldeHeures_status = 'warn';
  }else{
    soldeHeures_status = 'success';
  }

  return (
    <div className="soldes">
      {props.soldeEuros!==undefined && props.soldeHeures!==undefined ? (
      <Grid className="account" container spacing={2} justify="center" alignContent="center" alignItems="center">
        {props.soldeEuros!==undefined ? (
        <Grid item xs={6}>

          <KeyNumber
            label={'Solde'}
            value={props.soldeEuros+'€'}
            labelPosition={'top'}
            color={soldeEuros_status} />

        </Grid>
        ) : null}
        {props.soldeHeures!==undefined ? (
        <Grid item xs={6}>

          <KeyNumber
            label={'Solde'}
            value={(props.soldeHeures>0 ? '+' : '') + props.soldeHeures + 'h'}
            labelPosition={'top'}
            color={soldeHeures_status} />

        </Grid>
        ) : null}
      </Grid>
      ) : null}

      {props.plateformeURL!==undefined ? <Button color="primary" variant="contained" fullWidth={true} startIcon={<OpenInNewIcon />} href={props.plateformeURL} target={'_blank'}><strong>Accès plateforme</strong></Button> : null}

    </div>
  );
}

Soldes.propTypes = {
  soldeEuros: PropTypes.number,
  soldeHeures: PropTypes.number,
  plateformeURL: PropTypes.string
};

export default Soldes;
