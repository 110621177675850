import React from 'react';
import PropTypes from 'prop-types';
import './Maintenant.scss';

import Grid       from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import KeyNumber     from '../inc/KeyNumber';

function Maintenant(props:any) {

  let status = '';
  if(props.maintenant){
    if(!props.maintenant.isOpened){
      status = 'disabled';
    }else if(props.maintenant.permanences===undefined || props.maintenant.permanences===0){
      status = 'danger';
    }else{
      status = 'success';
    }
  }
  // console.log('Maintenant : ',props);

  return (
    <div className="maintenant">
      <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
        {props.maintenant!==undefined ? (
        <Grid item xs={6}>
          
          <div className={'now-status '+status}>
            <div className="sublabel">{props.maintenant.date}</div>
            <div className="sublabel">{props.maintenant.heure}</div>
            <div className="label">{props.maintenant.isOpened ? 'Ouvert' : 'Fermé'}</div>
          </div>

        </Grid>
        ) : null}
        {props.commandesEnAttente!==undefined ? (
        <Grid item xs={6}>

          <KeyNumber
            sub={'J\'ai'}
            label={'Commandes en attente'}
            value={props.commandesEnAttente+''}
            labelPosition={'bottom'}
            color={''} />

        </Grid>
        ) : null}
      </Grid>
      {props.commandesEnAttenteAll!==undefined ? <Typography variant="h6" color="textSecondary" align="center">Il y a <strong>{props.commandesEnAttenteAll}</strong> commandes non distribuées pour <strong>{props.commandesEnAttenteAllAdherents}</strong> personne(s).</Typography> : null}
    </div>
  );
}

Maintenant.propTypes = {
  commandesEnAttente: PropTypes.number,
  commandesEnAttenteAll: PropTypes.number,
  commandesEnAttenteAllAdherents: PropTypes.number,
  maintenant: PropTypes.object
};

export default Maintenant;
