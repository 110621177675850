import React from 'react';
import PropTypes from 'prop-types';
import './Aujourdhui.scss';

import Paper      from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import Avatar     from '@material-ui/core/Avatar';
import Chip       from '@material-ui/core/Chip';

import Table           from '@material-ui/core/Table';
import TableBody       from '@material-ui/core/TableBody';
import TableCell       from '@material-ui/core/TableCell';
import TableContainer  from '@material-ui/core/TableContainer';
import TableHead       from '@material-ui/core/TableHead';
import TableRow        from '@material-ui/core/TableRow';

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import PhoneIcon from '@material-ui/icons/Phone';

function Aujourdhui(props:any) {
  if(!props.aujourdhui)
    return null;

  return (
    <div className="aujourdhui success">
      <Typography variant="h6" color="textPrimary" align="right">Aujourd'hui</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Aujourd'hui" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">Heure <span className="secondary-info">(Durée)</span></TableCell>
              <TableCell component="th" scope="row">Catégorie & Tâche</TableCell>
              <TableCell component="th" scope="row">Participants</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.aujourdhui.map((creneau,index) => {
              let startDate = creneau.dateinmillis1;
              let endDate = creneau.dateinmillis2;
              let nowDate = creneau.dateinmillis0;
              let pasted = false;
              let current = false;
              if(endDate < nowDate){
                pasted = true;
              }else if(startDate <= nowDate){
                current = true;
              }
              console.log('Creneau',creneau,nowDate,pasted,current);
                

              let status = '';
              if(!creneau.participants || creneau.participants.length===0){
                status = 'danger';
              }else if(creneau.participants.length<creneau.places && creneau.designation==='epicerie'){
                status = 'warn';
              }else{
                status = 'success';
              }
              return (
                <TableRow key={index} className={(current ? ' current-hour' : '') + (pasted ? ' pasted-hour' : '') + (creneau.designation==='epicerie' ? ' epicerie' : '')}>
                  <TableCell className={'status '+status}>{creneau.heure} <span className="secondary-info">({creneau.duree})</span></TableCell>
                  <TableCell  className={'status '+status} align="left">
                    <span>{creneau.lieu}<br/>{creneau.tache}</span>
                  </TableCell>
                  <TableCell>
                    {creneau.participants && creneau.participants.length>0 ? creneau.participants.map((personne,index2) => {
                      return (
                        <div key={index2}><Chip label={personne.nom} onDelete={() => props.onCall(personne)} deleteIcon={<PhoneIcon />}/></div>
                      );
                    }) : null}
                    {!creneau.participants || creneau.participants.length===0 ? (
                      <div className={'status '+status}>Aucun participant</div>
                    ) : null}
                    {!creneau.participants || creneau.participants.length < creneau.places ? (
                      <div className={'status '+status}>Il reste {creneau.places - creneau.participants.length} place(s)</div>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Aujourdhui.propTypes = {
  aujourdhui: PropTypes.arrayOf(PropTypes.object),
  onCall: PropTypes.func
};

export default Aujourdhui;
