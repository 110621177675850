import React from 'react';
import PropTypes from 'prop-types';
import './Participations.scss';

import Paper      from '@material-ui/core/Paper';

import Typography      from '@material-ui/core/Typography';

import Table           from '@material-ui/core/Table';
import TableBody       from '@material-ui/core/TableBody';
import TableCell       from '@material-ui/core/TableCell';
import TableContainer  from '@material-ui/core/TableContainer';
import TableHead       from '@material-ui/core/TableHead';
import TableRow        from '@material-ui/core/TableRow';

function Participations(props:any) {
  if(!props.participations || props.participations.length===0)
    return null;

  return (
    <div className="participations">
      <Typography variant="h6" color="textPrimary" align="right">Mes prochaines participations</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Participations" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">Date</TableCell>
              <TableCell component="th" scope="row">Heure <span className="secondary-info">(Durée)</span></TableCell>
              <TableCell component="th" scope="row">Catégorie & Tâche</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.participations.map((participation,index) => (
            <TableRow key={index}>
              <TableCell>{participation.date}</TableCell>
              <TableCell>{participation.heure} <span className="secondary-info">({participation.duree})</span></TableCell>
              <TableCell>{participation.lieu}<br/>{participation.tache}</TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Participations.propTypes = {
  participations: PropTypes.arrayOf(PropTypes.object)
};

export default Participations;
