import React from 'react';
import PropTypes from 'prop-types';
import './Contact.scss';


import Grid       from '@material-ui/core/Grid';

import Button     from '@material-ui/core/Button';

import MailIcon from '@material-ui/icons/Mail';

function Contact(props:any) {
  if(!props.email)
    return null;

  return (
    <div className="contact">
      <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
        <Grid item xs={8}>
          <div className="mail" dangerouslySetInnerHTML={{__html: props.email}}></div>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" size="small" fullWidth={true} startIcon={<MailIcon />} href={'mailto:'+props.email}>Contact</Button>
        </Grid>
      </Grid>
    </div>
  );
}

Contact.propTypes = {
  email: PropTypes.string
};

export default Contact;
