import React from 'react';
import PropTypes from 'prop-types';
import './Commandes.scss';

import Paper      from '@material-ui/core/Paper';

import Typography      from '@material-ui/core/Typography';
import Button     from '@material-ui/core/Button';

import Table           from '@material-ui/core/Table';
import TableBody       from '@material-ui/core/TableBody';
import TableCell       from '@material-ui/core/TableCell';
import TableContainer  from '@material-ui/core/TableContainer';
import TableHead       from '@material-ui/core/TableHead';
import TableRow        from '@material-ui/core/TableRow';

import DialogTitle     from '@material-ui/core/DialogTitle';
import Dialog          from '@material-ui/core/Dialog';
import DialogActions   from '@material-ui/core/DialogActions';
import DialogContent   from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import ListIcon from '@material-ui/icons/List';
import TodayIcon from '@material-ui/icons/Today';

function Commandes(props:any) {
  if(!props.commandes || props.commandes.length===0)
    return null;

  return (
    <div className="commandes">
      <Typography variant="h6" color="textPrimary" align="right">Mes commandes en attente</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Commandes" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">Passée le</TableCell>
              <TableCell component="th" scope="row" align="center">Produits</TableCell>
              <TableCell component="th" scope="row" align="right">Montant</TableCell>
              <TableCell component="th" scope="row"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.commandes.map((commande,index) => (
            <TableRow key={index}>
              <TableCell>{commande.date}</TableCell>
              <TableCell align="center">{commande.produits ? commande.produits.length : ''}</TableCell>
              <TableCell align="right">{commande.montant}</TableCell>
              <TableCell><Button color="secondary" variant="contained" size="small" startIcon={<ListIcon />} onClick={() => props.onOpenCommandeDetails(commande)}>Détails</Button></TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog onClose={props.handleCloseCommandeDetails} aria-labelledby="Détails" fullScreen open={props.isCommandeDetailsOpened}>
        <DialogTitle id="commande-dialog">
          {props.commandeDetails ? (
            <>
            <TodayIcon /> <span>{props.commandeDetails.date} </span><span><i>(id : {props.commandeDetails.id})</i></span>
            <Typography variant="h5" color="textPrimary" align="right"><strong>Prix total : {props.commandeDetails.montant}</strong></Typography>
          </>
          ) : null}
        </DialogTitle>
        <DialogContent className="dialog-wrapper">
          {props.commandeDetails && props.commandeDetails.produits ? (
          <>
          <Typography variant="h6" color="textPrimary" align="right" className="table-title">Produits en attente de retrait / validation</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Commandes" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row"><strong></strong></TableCell>
                  <TableCell component="th" scope="row"><strong>Produit</strong></TableCell>
                  <TableCell component="th" scope="row" align="center"><strong>Quantité</strong></TableCell>
                  <TableCell component="th" scope="row" align="center"><strong>Stocks</strong></TableCell>
                  <TableCell component="th" scope="row"><strong>Prix unitaire</strong></TableCell>
                  <TableCell component="th" scope="row" align="right"><strong>Prix</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.commandeDetails.produits.map((produit,index) => (
                <TableRow key={index} className={produit.dejaRetire ? 'disabled' : ''}>
                  <TableCell align="left">{produit.type}</TableCell>
                  <TableCell align="left">{produit.label}</TableCell>
                  <TableCell align="center">{produit.quantite} {produit.unite}</TableCell>
                  <TableCell align="center">{produit.stock}</TableCell>
                  <TableCell align="left">{produit.prixUnitaireEuros}€ / {produit.unitePrix}</TableCell>
                  <TableCell align="right">{produit.prixEuros}€</TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="legend">
            <div>* <i>Stocks</i> : stock commandable / stock en rayon</div>
            <div>* <i>u</i> : produit à l'unité = pièce, ou référence sachet</div>
            <div>* <i>kg/L</i> : produit au kg ou L</div>
          </div>
          </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={props.handleCloseCommandeDetails}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Commandes.propTypes = {
  commandes: PropTypes.arrayOf(PropTypes.object),
  onOpenCommandeDetails: PropTypes.func,
  handleCloseCommandeDetails: PropTypes.func,
  isCommandeDetailsOpened: PropTypes.bool,
  commandeDetails: PropTypes.object
};

export default Commandes;
