import React from 'react';
import PropTypes from 'prop-types';
import './Ardoise.scss';

function Ardoise(props:any) {
  if(!props.richText && !props.updateText)
    return null;

  return (
    <div className="ardoise">
      <div className="panel">
        <div dangerouslySetInnerHTML={{__html: props.richText}}></div>
      </div>
      <div className="update">Dernière mise à jour : {props.updateText}</div>
    </div>
  );
}

Ardoise.propTypes = {
  richText: PropTypes.any,
  updateText: PropTypes.string
};

export default Ardoise;
