import React from 'react';
import PropTypes from 'prop-types';
import './KeyNumber.scss';

function KeyNumber(props:any) {

  return (
    <div className={'keyNumber ' + props.color}>
      <div className="keyNumber-circle">
        <div className="keyNumber-texts">
          {props.sub ? <div className="keyNumber-sub">{props.sub}</div> : null}
          {props.labelPosition==='top' ? <div className="keyNumber-label">{props.label}</div> : null}
          <div className="keyNumber-value">{props.value}</div>
          {props.labelPosition==='bottom' ? <div className="keyNumber-label">{props.label}</div> : null}
        </div>
      </div>
    </div>
  );
}

KeyNumber.propTypes = {
  sub: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top','bottom']),
  color: PropTypes.oneOf(['danger','warn','success','disabled',''])
};

export default KeyNumber;
