import React from 'react';
import PropTypes from 'prop-types';
import './Adresse.scss';


import Grid       from '@material-ui/core/Grid';

import Button     from '@material-ui/core/Button';

import RoomIcon   from '@material-ui/icons/Room';

function Adresse(props:any) {
  if(!props.adresseText)
    return null;

  return (
    <div className="adresse">
      <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
        <Grid item xs={8}>
          <address dangerouslySetInnerHTML={{__html: props.adresseText}}></address>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" size="small" fullWidth={true} startIcon={<RoomIcon />} href={props.gmapURL} target={'_blank'}>Carte</Button>
        </Grid>
      </Grid>
    </div>
  );
}

Adresse.propTypes = {
  adresseText: PropTypes.string,
  gmapURL: PropTypes.string
};

export default Adresse;
