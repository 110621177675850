import React from 'react';
import PropTypes from 'prop-types';
import './Horaires.scss';

import Paper      from '@material-ui/core/Paper';

import Typography      from '@material-ui/core/Typography';

import Table           from '@material-ui/core/Table';
import TableBody       from '@material-ui/core/TableBody';
import TableCell       from '@material-ui/core/TableCell';
import TableContainer  from '@material-ui/core/TableContainer';
// import TableHead       from '@material-ui/core/TableHead';
import TableRow        from '@material-ui/core/TableRow';

function Horaires(props:any) {
  if(!props.horaires || props.horaires.length===0)
    return null;

  let todayNumber = (new Date()).getDay();

  return (
    <div className="horaires">
      <Typography variant="h6" color="textPrimary" align="right">Horaires habituelles</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Horaires" size="small">
          <TableBody>
            <TableRow className={todayNumber===1 ? 'current-day' : ''}>
              <TableCell>Lundi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.lundi || !props.horaires.lundi[0] ? <span className="day-closed">fermé</span> : props.horaires.lundi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.lundi || !props.horaires.lundi[1] ? <span className="day-closed">fermé</span> : props.horaires.lundi[1]}</TableCell>
            </TableRow>
            <TableRow className={todayNumber===2 ? 'current-day' : ''}>
              <TableCell>Mardi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.mardi || !props.horaires.mardi[0] ? <span className="day-closed">fermé</span> : props.horaires.mardi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.mardi || !props.horaires.mardi[1] ? <span className="day-closed">fermé</span> : props.horaires.mardi[1]}</TableCell>
            </TableRow>
            <TableRow className={todayNumber===3 ? 'current-day' : ''}>
              <TableCell>Mercredi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.mercredi || !props.horaires.mercredi[0] ? <span className="day-closed">fermé</span> : props.horaires.mercredi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.mercredi || !props.horaires.mercredi[1] ? <span className="day-closed">fermé</span> : props.horaires.mercredi[1]}</TableCell>
            </TableRow>
            <TableRow className={todayNumber===4 ? 'current-day' : ''}>
              <TableCell>Jeudi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.jeudi || !props.horaires.jeudi[0] ? <span className="day-closed">fermé</span> : props.horaires.jeudi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.jeudi || !props.horaires.jeudi[1] ? <span className="day-closed">fermé</span> : props.horaires.jeudi[1]}</TableCell>
            </TableRow>
            <TableRow className={todayNumber===5 ? 'current-day' : ''}>
              <TableCell>Vendredi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.vendredi || !props.horaires.vendredi[0] ? <span className="day-closed">fermé</span> : props.horaires.vendredi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.vendredi || !props.horaires.vendredi[1] ? <span className="day-closed">fermé</span> : props.horaires.vendredi[1]}</TableCell>
            </TableRow>
            <TableRow className={todayNumber===6 ? 'current-day' : ''}>
              <TableCell>Samedi</TableCell>
              <TableCell>{!props.horaires || !props.horaires.samedi || !props.horaires.samedi[0] ? <span className="day-closed">fermé</span> : props.horaires.samedi[0]}</TableCell>
              <TableCell>{!props.horaires || !props.horaires.samedi || !props.horaires.samedi[1] ? <span className="day-closed">fermé</span> : props.horaires.samedi[1]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

Horaires.propTypes = {
  horaires: PropTypes.any
};

export default Horaires;
